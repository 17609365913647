@import "themes/light.theme";

@mixin light-field {
  .mdc-text-field--outlined {
    color: $light-gray-400;
    font-family: Inter;
  }
  .mat-mdc-form-field-flex {
    align-self: center;
  }
  .mat-mdc-form-field-infix {
    align-self: center;
  }
  .mdc-text-field--outlined.mdc-text-field:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading {
    border-color: $light-gray;
  }
  .mdc-text-field--outlined.mdc-text-field:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch {
    border-color: $light-gray;
  }
  .mdc-text-field--outlined.mdc-text-field:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-color: $light-gray;
  }
  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none !important;
    height: 0 !important;
  }
}
