@mixin custom-bottom-sheet {
  .mat-bottom-sheet-container {
    position: fixed;
    border-radius: 1rem 1rem 0rem 0rem;
    left: 0;
    padding: 0 !important;
    background: $white !important;
    scrollbar-width: none;
    @media (orientation: landscape) {
      top: calc(70vh - 3.88875rem);
      min-height: calc(40vh + 5.01375rem);
    }
    @media (orientation: portrait) {
      top: calc(83.49vh - 3.88875rem);
      min-height: calc(16.51vh + 5.01375rem);
    }
  }
  .mat-bottom-sheet-container-medium.cdk-dialog-container {
    min-width: -webkit-fill-available;
  }
}
