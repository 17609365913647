@import "themes/light.theme";

@mixin custom-spinner() {
  circle {
    stroke: $light-blue !important;
  }
  .white-spinner {
    circle {
      stroke: $white !important;
    }
  }
}
