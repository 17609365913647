@import "themes/light.theme";

@mixin custom-radio-button() {
  .mdc-form-field > label {
    padding-left: 0px;
  }
  .mat-mdc-radio-checked {
    .mdc-form-field > label {
      color: $primary-500;
    }
  }
  .mdc-radio {
    padding-right: 0.5rem;
    padding-left: 0px;
  }
  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #4774f1;
    --mdc-radio-selected-hover-icon-color: #4774f1;
    --mdc-radio-selected-icon-color: #4774f1;
    --mdc-radio-selected-pressed-icon-color: #4774f1;
    --mat-radio-checked-ripple-color: #4774f1;
  }
}
