/* You can add global styles to this file, and also import other style files */
@import "dashboard-frontend-library";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body {
  margin: 0;
  display: grid;
  user-select: none;
  width: 100%;
  height: 100%;
  font-family: "DM Sans", sans-serif;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.mat-mdc-form-field {
  @include light-field;
}

.app-camera-selection {
  .mat-bottom-sheet-container {
    @media (orientation: portrait) {
      top: calc(48vh - 3.88875rem);
      min-height: calc(52vh + 5.01375rem);
    }
  }
}