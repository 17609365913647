@mixin typography() {
  h1 {
    font-weight: 700;
    font-size: clamp(2rem, 3vw, 4rem);
    line-height: 54.06px;
  }

  h2 {
    font-weight: 700;
    font-size: clamp(1.75rem, 1.875vw, 3.75rem);
    line-height: 1;
    &.regular {
      font-weight: 400;
    }
  }

  h3 {
    font-family: Poppins;
    font-weight: 700;
    font-size: clamp(1.5rem, 1.25vw, 2.75rem);
    line-height: 2.25rem;
  }

  h4 {
    font-weight: 700;
    font-size: clamp(1.3125rem, 1.15vw, 2rem);
    line-height: 1.4rem;
    &.regular {
      font-weight: 400;
    }
  }

  h5 {
    font-weight: 400;
    font-size: clamp(1.25rem, 1.35vw, 1.8rem);
    line-height: 26.5px;
  }

  .subtitle {
    font-weight: 400;
    font-size: clamp(1rem, 0.8vw, 1.5rem);
    line-height: 1.125rem;

    &.bolder {
      font-weight: 600;
    }
  }

  .subtitle-bold {
    font-weight: 700;
    font-size: clamp(1rem, 0.8vw, 1.5rem);
    line-height: 18px;
  }

  .subtitle2 {
    font-weight: 500;
    font-size: clamp(0.95rem, 0.75vw, 1.5rem);
    line-height: 15px;
  }

  .subtitle3 {
    font-weight: 400;
    font-size: clamp(1.35rem, 1.15vw, 2rem);
    line-height: 22.26px;
  }

  .body1 {
    font-weight: 400;
    font-size: clamp(0.95rem, 0.9vw, 1.2rem);
    line-height: 18px;
  }

  .body1-bold {
    font-weight: 700;
    font-size: clamp(0.95rem, 0.9vw, 1.2rem);
    line-height: 18px;
  }

  .button-text {
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
  }

  .caption {
    font-weight: 400;
    font-size: clamp(0.5rem, 0.7vw, 1rem);
    line-height: 14px;
  }
  .caption-bold {
    font-weight: 700;
    font-size: clamp(0.5rem, 0.7vw, 1rem);
    line-height: 14px;
  }

  .bold {
    font-weight: 700;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .text-base {
    text-align: center;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;

    &.bolder {
      font-weight: 700;
    }
    &.bold {
      font-weight: 700;
    }
    &.text-box {
      line-height: 1.125rem;
    }
  }

  .text-small {
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.875rem;

    &.bold {
      font-weight: 700;
    }

    &.text-box {
      line-height: 1.125rem;
    }
  }

  .text-L {
    font-weight: 400;
    font-size: clamp(1rem, 0.8vw, 1.5rem);
    line-height: 1.125rem;
  }

  .text-XL {
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.375rem;

    &.bolder {
      font-weight: 600;
    }
  }

  .font-family-inter {
    font-family: Inter;
  }

  .font-family-poppins {
    font-family: Poppins;
  }
}
