@import "themes/light.theme";

@mixin custom-input() {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  input {
    border: none;
    outline: none;
    width: 100%;
  }

  .input-wrapper {
    border-radius: 0.375rem;
    display: flex;
    height: 3rem;
    padding: 0rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid $faded-gray;
    background: $white;
    width: 100%;
  }
}
