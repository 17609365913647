// colors
$link-blue: #4774f1;
$dark-blue: #14105b;
$faded-dark-blue: #5b588c;
$light-blue: #2f60eb;
$light-blue-100: #d1d9f3;
$faded-light-blue: #2f60eb80;
$faded-gray: #e4e7ec;
$light-gray: #d7d7d7;
$light-gray-400: #98a2b3;
$light-gray-300: #d0d5dd;
$light-gray-100: #f2f4f7;
$light-gray-50: #ecf0fa;
$dark-gray: #344054;
$gray-600: #00000061;
$gray: #e3e3e3;
$white: #ffffff;
$light-red: #ffd3d5;
$dark-red: #a80513;
$error: #ec1b25;
$red-label: #e73252;
$primary-100: #edf2ff;
$primary-500: #4774f1;
$gray-neutral-500: #667085;
$faded-gray-200: #f6f9fe;
$gray-200: #8f98bc;
$blue-200: #2b2864;
$live-blue: #5a82f5;
$dark-blue-overlay: #14105b;
$dark-blue-shadow: #14105b0d;
$valid-color: rgba(13, 163, 55, 0.2);
$invalid-color: rgba(236, 27, 37, 0.2);
$write-color: rgba(71, 116, 241, 0.2);

@font-face {
  font-family: "DM Sans";
  src: url("../../assets/fonts/DMSans.ttf");
}

@mixin text-field {
  color: $faded-light-blue;
}

@mixin header-background {
  background-color: $dark-blue;
}

@mixin small-title {
  color: $dark-blue;
  font-size: 1.066rem;
  font-weight: 400;
  line-height: 1.275rem;
  font-family: "DM Sans";
}

@mixin link-text {
  color: $link-blue;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 0.9375rem;
  font-family: "DM Sans";
}

@mixin faded-text {
  color: $dark-gray;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.275rem;
  font-family: "DM Sans";
}

@mixin large-title {
  color: $dark-blue;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.1925rem;
  font-family: "DM Sans";
}

@mixin larger-title {
  color: $dark-gray;
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
}

@mixin large-title-negative {
  color: $white;
  font-size: 1rem;
  font-weight: bold;
  font-family: "DM Sans";
}

@mixin blue-button {
  &.disabled {
    background-color: $light-gray-100;
    color: $light-gray-300;
    border: 1px solid $light-gray-300;
  }
  &.mat-mdc-unelevated-button[disabled] {
    background-color: $light-gray-100;
    color: $light-gray-300;
    border: 1px solid $light-gray-300;
  }
  background-color: $light-blue;
  color: $white;
  display: flex;
  height: 2.625rem !important;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
}

@mixin light-theme() {
  .text-dark-gray {
    color: $dark-gray;
  }
}
