@mixin mapbox() {
  .mapboxgl-map {
    font: 1rem 'DM Sans' !important;
  }
  .hide-mapbox-popup-content {
    visibility: hidden;
  }

  .mapboxgl-ctrl-attrib {
    display: none;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
  .mapboxgl-popup {
    .mapboxgl-popup-content {
      background: transparent;
      display: flex;
      position: absolute;
      align-items: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.8125rem;
      line-height: 0.86125rem;
      white-space: nowrap;
      .icon {
        height: 1.3em;
        margin-right: 0.4rem;
      }
      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &.hidden .mapboxgl-popup-content {
      display: none;
    }

    &.red .mapboxgl-popup-content {
      border-radius: 0.125rem;
      background-color: $red-label;
      padding: 0.1875rem 0.5rem;
      color: $white;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1rem;
    }

    &.gray .mapboxgl-popup-content {
      border-radius: 0.125rem;
      background-color: $gray-600;
      padding: 0.1875rem 0.5rem;
      color: $white;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1rem;
    }

    &.hidden-polygon-label {
      visibility: hidden;
    }
  }
}
